<template>
    <div class="container-fluid">
        <router-view />
    </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600&display=swap");
body {
    font-family: "Comfortaa", cursive !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    overflow-x: hidden !important;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: rgba(126, 239, 104, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
        0 0 8px rgba(126, 239, 104, 0.6);
    outline: 0 none;
}
btn-default:focus,
.btn-default:active,
.btn-default.active {
    background-color: #ffffff;
}

.btn-default:hover {
    background-color: #ebebeb;
}

.spinner {
    width: 100%;
}
.header {
    background: white !important;
    height: 70px;
    position: sticky !important;
    top: 0;
}
.btn:focus,
.btn.Focus {
    outline: 0 !important;
    box-shadow: none !important;
}
input,
textarea {
    outline: none;
    box-shadow: none !important;
    border: 1px solid #ccc !important;
}
.container-fluid {
    padding-left: 0 !important;
}
.ql-editor {
    min-height: 45vh !important;
    font-family: "Comfortaa", cursive !important;
} /* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
