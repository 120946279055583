<template>
    <quill-editor
        el="div"
        ref="quil"
        v-model:value="html"
        :options="editorOption"
        @blur="onEditorBlur($event)"
        @focus="onEditorFocus($event)"
        @ready="onEditorReady($event)"
        @change="onEditorChange($event)"
    />
</template>
<script>
export default {
    props: {
        content: String,
    },
    data() {
        return {
            html: this.content,
            editorOptions: {
                toolbar: [["bold", "italic", "image"]],
                handlers: {
                    // handlers object will be merged with default handlers object
                    link: function (value) {
                        if (value) {
                            var href = prompt("Enter the URL");
                            this.quill.format("link", href);
                        } else {
                            this.quill.format("link", false);
                        }
                    },
                },
            },
        };
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
        onEditorBlur(quill) {},
        // eslint-disable-next-line no-unused-vars
        onEditorFocus(quill) {},
        // eslint-disable-next-line no-unused-vars
        onEditorReady(quill) {},
        // eslint-disable-next-line no-unused-vars
        onEditorChange({ quill, html, text }) {
            this.html = html;
        },
    },
};
</script>
<style lang=""></style>
