import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
    state: {
        user: null,
    },
    getters: {
        getUser(state) {
            return state.user;
        },
    },
    mutations: {
        LOGIN_SUCCESS(state, user) {
            state.user = user;
        },
        LOGOUT(state) {
            state.user = null;
        },
    },
    actions: {
        LOGOUT(context) {
            context.commit("LOGOUT");
        },
    },
    modules: {},
    plugins: [
        createPersistedState({
            key: "YouthInitiate Store",
            paths: ["user"],
        }),
    ],
});
export default store;
