<template>
    <router-view></router-view>
</template>
<script>
export default {};
</script>
<style lang="css" scoped>
.auth {
    background: #8f8f8f !important;
}
</style>
