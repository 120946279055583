<template>
    <div class="about">
        <div class="header d-flex justify-content-between">
            <h3 class="title">About Us</h3>
            <div v-if="edit" class="d-flex">
                <button @click="toggleEdit" class="btn primary-color">
                    Discard
                </button>
                <button :disabled="loading" class="btn primary" @click="save">
                    <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm mx-2"
                    ></span>
                    <span>Save</span>
                </button>
            </div>
            <div v-else class="d-flex">
                <button @click="toggleEdit" class="btn primary-color">
                    Edit
                </button>
                <button class="btn primary">Preview</button>
            </div>
        </div>
        <div v-if="edit">
            <div class="editor">
                <Editor ref="editor" :content="content" />
            </div>
        </div>
        <div class="content" v-else v-html="content"></div>
        <div v-show="page_loading" class="container text-center">
            <b-spinner class="m-5" label="Spinning"></b-spinner>
        </div>
    </div>
</template>
<script>
import Editor from "../components/Editor.vue";
import services from "../services/apiService";
export default {
    components: {
        Editor,
    },
    data() {
        return {
            edit: false,
            content: "",
            disabled: false,
            loading: false,
            page_loading: true,
        };
    },
    methods: {
        toggleEdit() {
            this.edit = !this.edit;
        },
        async save() {
            this.loading = true;
            let data = new FormData();
            data.append("content", this.$refs.editor.html);
            var result = await services.updateAbout(data);
            this.loading = false;
            this.content = result.data.html;
            this.edit = false;
        },
    },
    mounted: async function () {
        var content = await services.getAbout();
        this.content = content.data.html;
        this.page_loading = false;
    },
};
</script>
<style lang="css" scoped>
.about {
    padding: 10px;
}

.content {
    width: 100% !important;
}
</style>
