<template>
    <tr>
        <td>{{ message.name }}</td>
        <td>{{ message.mobile }}</td>
        <td>{{ message.email }}</td>
        <td>
            {{ date(message.createdAt) }}
        </td>
        <td>{{ message.message }}</td>
    </tr>
</template>
<script>
import moment from "moment";

export default {
    props: {
        message: Object,
    },
    methods: {
        date() {
            return moment().format("MMMM DD, yyyy");
        },
    },
};
</script>
<style lang="css"></style>
