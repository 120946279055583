<template>
    <div class="new-article">
        <div class="header d-flex justify-content-between">
            <h3 class="title">{{ $route.name }} - {{ news.title }}</h3>
            <div class="d-flex">
                <button @click="$router.go(-1)" class="btn">
                    <span>Cancel</span>
                </button>
                <button @click="save(true)" class="btn primary">
                    <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm mx-2"
                    ></span>
                    <span>Save</span>
                </button>
            </div>
        </div>
        <form>
            <div class="row">
                <div class="col-md-3 banner-preview">
                    <div>
                        <img v-if="bannerPreview" :src="bannerPreview" />

                        <img
                            style="cursor: pointer"
                            @click.prevent="$refs.bannerUpload.click()"
                            v-else
                            src="https://user-images.githubusercontent.com/101482/29592647-40da86ca-875a-11e7-8bc3-941700b0a323.png"
                        />
                    </div>
                </div>
                <div class="col-md">
                    <div>
                        <div class="d-flex">
                            <input
                                required
                                type="text"
                                v-model="news.title"
                                style="margin-right: 10px"
                                class="form-control"
                                placeholder="Enter article title (required*)"
                            />
                            <input
                                v-model="news.subtitle"
                                required
                                type="text"
                                placeholder="Enter article sub title"
                            />
                        </div>
                        <div>
                            <input
                                v-model="news.tags"
                                type="text"
                                class="form-control"
                                placeholder="Enter tags seperated by comma. eg: donate, help, children"
                            />
                        </div>
                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="types d-flex">
                                    <button
                                        @click.prevent="news.type = type"
                                        v-for="(type, index) in types"
                                        :key="index"
                                        :class="
                                            news.type === type ? 'selected' : ''
                                        "
                                        class="btn"
                                    >
                                        {{ type }}
                                    </button>
                                </div>
                            </div>
                            <div class="d-flex" v-if="news.type === 'Event'">
                                <label for="when">When is this event?</label>
                                <input
                                    required
                                    name="when"
                                    class="form-control"
                                    type="datetime-local"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <input
                        @change="bannerSelected"
                        type="file"
                        required
                        ref="bannerUpload"
                        hidden
                    />
                </div>
            </div>
            <label for="summary">Summary</label>
            <textarea
                v-model="news.summary"
                placeholder="Article summary. If you dont specify a summary first paragraph text will be considered as summary with first 450 characters including white spaces. It is recommended to specify a summary for better search engine optimization"
                class="form-control my-2"
                style="resize: none"
                name="summary"
                id=""
                cols="30"
                rows="3"
            ></textarea>
        </form>
        <Editor ref="editor" :content="content" />
        <div class="my-2 d-flex justify-content-between">
            <h6>Add Gallery photos</h6>
            <div>
                <input
                    @change="gallerySelected"
                    type="file"
                    ref="galleryUpload"
                    multiple
                    hidden
                />
                <button @click="uploadClick" class="btn primary">Upload</button>
            </div>
        </div>
        <div class="gallery">
            <img
                v-for="(media, index) in galleryPreviews"
                :key="index"
                :src="media"
            />
        </div>
    </div>
</template>
<script>
import Editor from "../../components/Editor.vue";
import apiService from "../../services/apiService";
export default {
    components: {
        Editor,
    },
    data() {
        return {
            types: ["News", "Event", "Project"],
            news: {
                title: null,
                subtitle: null,
                body: null,
                type: "News",
                tags: null,
                startingDate: null,
                summary: null,
                file: null,
                shouldBePublished: false,
                gallery: null,
            },
            bannerPreview: null,
            bannerFile: null,
            galleryPreviews: [],
            galleryFiles: [],
            loading: false,
        };
    },
    methods: {
        uploadClick() {
            this.$refs.galleryUpload.click();
        },

        gallerySelected(e) {
            this.galleryPreviews = [];
            this.galleryFiles = [];
            const selectedFiles = e.target.files;
            for (let i = 0; i < selectedFiles.length; i++) {
                var preview = URL.createObjectURL(selectedFiles[i]);
                this.galleryPreviews.push(preview);
                this.galleryFiles.push(selectedFiles[i]);
            }
        },
        bannerSelected(e) {
            this.bannerPreview = null;
            this.bannerFile = null;
            const selectedFile = e.target.files[0];
            var preview = URL.createObjectURL(selectedFile);
            this.bannerPreview = preview;
            this.bannerFile = selectedFile;
        },
        async save(canPublish) {
            let form = new FormData();

            form.append("title", this.news.title);
            form.append("subtitle", this.news.subtitle);
            form.append("body", this.$refs.editor.html);
            form.append("type", this.news.type);
            form.append("tags", this.news.tags);
            form.append("summary", this.news.summary);
            if (this.news.startingDate) {
                form.append("startingDate", this.news.startingDate);
            }
            form.append("shouldBePublished", canPublish);
            form.append("file", this.bannerFile);

            this.galleryFiles.forEach((file) => {
                form.append("gallery", file);
            });

            this.loading = true;
            await apiService.saveNews(form);
            this.loading = false;
            this.$router.go(-1);
        },
    },
};
</script>
<style lang="css" scoped>
.types .selected {
    background: rgb(68, 141, 68);
    color: white;
}

.types .btn {
    border-radius: 25px !important;
    height: 30px;
    font-size: 12px;
}
.controls {
    display: flex;
}

.radio {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
input {
    margin-bottom: 10px;
    width: 100% !important;
}
.gallery {
    float: right;
}
.gallery img {
    background-size: contain;
    height: 300px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.banner-preview img {
    background-size: contain;
    margin-right: 10px;
    width: 100%;
    height: 180px;
    margin-bottom: 10px;
}
</style>
