<template>
    <div v-if="!page_loading" class="home">
        <div class="header d-flex justify-content-between">
            <h3 class="title">{{ $route.name }}</h3>
            <div class="d-flex">
                <button class="btn primary">Save</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md banners">
                <h5>Banner slideshow</h5>
                <p>You may upload images & videos that are widescreen</p>
                <BannerComponent
                    @remove="remove"
                    :class="
                        banner.id === selectedBanner ? 'selected-banner' : ''
                    "
                    @click="selectedBanner = banner.id"
                    v-for="banner in content.banners"
                    :key="banner.id"
                    :banner="banner"
                />
            </div>
        </div>
    </div>
    <div v-else class="container text-center">
        <b-spinner class="m-5" label="Spinning"></b-spinner>
    </div>
</template>
<script>
import BannerComponent from "../components/BannerComponent.vue";
import apiService from "../services/apiService";
export default {
    components: {
        BannerComponent,
    },
    data() {
        return {
            selectedBanner: null,
            mediaSelector: true,
            content: null,
            page_loading: true,
        };
    },
    methods: {
        remove(media) {
            this.content.banners.splice(this.content.banners.indexOf(media), 1);
        },
    },
    mounted: function () {
        apiService.getHome().then((res) => {
            this.content = res.data;
            this.page_loading = false;
        });
    },
};
</script>
<style lang="css" scoped>
.home {
    overflow: hidden !important;
}

.banners {
    overflow-y: auto;
}

.selected-banner {
    background: rgb(185, 185, 185);
}
</style>
