<template>
    <div v-if="news" class="new-article">
        <div class="header d-flex justify-content-between">
            <h3 class="title">{{ $route.name }} - {{ news.title }}</h3>
            <div class="d-flex">
                <button @click="save(false)" class="btn">
                    <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm mx-2"
                    ></span>
                    <span>Save</span>
                </button>
                <button @click="save(true)" class="btn primary">
                    <span
                        v-show="loading"
                        class="spinner-border spinner-border-sm mx-2"
                    ></span>
                    <span>Publish</span>
                </button>
            </div>
        </div>
        <Editor ref="editor" :content="news.body" />
    </div>
</template>
<script>
import Editor from "../../components/Editor.vue";
import apiService from "../../services/apiService";
export default {
    components: {
        Editor,
    },
    data() {
        return {
            types: ["News", "Event", "Project"],
            news: null,
            bannerPreview: null,
            bannerFile: null,
            galleryPreviews: [],
            galleryFiles: [],
            loading: false,
        };
    },
    methods: {
        uploadClick() {
            this.$refs.galleryUpload.click();
        },

        gallerySelected(e) {
            this.galleryPreviews = [];
            this.galleryFiles = [];
            const selectedFiles = e.target.files;
            for (let i = 0; i < selectedFiles.length; i++) {
                var preview = URL.createObjectURL(selectedFiles[i]);
                this.galleryPreviews.push(preview);
                this.galleryFiles.push(selectedFiles[i]);
            }
        },
        bannerSelected(e) {
            this.bannerPreview = null;
            this.bannerFile = null;
            const selectedFile = e.target.files[0];
            var preview = URL.createObjectURL(selectedFile);
            this.bannerPreview = preview;
            this.bannerFile = selectedFile;
        },
        async save() {
            let form = new FormData();
            form.append("body", this.$refs.editor.html);
            await apiService.updateNews(this.news.id, form);
            this.$router.go(-1);
        },
    },
    mounted: async function () {
        var id = this.$route.query.id;
        this.loading = true;
        var result = await apiService.getArticle(id);
        this.news = result.data;
        this.loading = false;
    },
};
</script>
<style lang="css" scoped>
.types .selected {
    background: rgb(68, 141, 68);
    color: white;
}

.types .btn {
    border-radius: 25px !important;
    height: 30px;
    font-size: 12px;
}
.controls {
    display: flex;
}

.radio {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
input {
    margin-bottom: 10px;
    width: 100% !important;
}
.gallery {
    float: right;
}
.gallery img {
    background-size: contain;
    height: 300px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.banner-preview img {
    background-size: contain;
    margin-right: 10px;
    width: 100%;
    height: 180px;
    margin-bottom: 10px;
}
</style>
