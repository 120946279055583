<template>
    <div class="row">
        <Navbar />
        <div class="col-md content">
            <transition name="slide-fade">
                <router-view></router-view>
            </transition>
        </div>
    </div>
</template>
<script>
import Navbar from "@/components/Navbar.vue";
export default {
    components: { Navbar },
    watch: {
        // eslint-disable-next-line no-unused-vars
        "$store.state.user"(a, b) {
            if (a === null) {
                this.$router.push("/auth/login");
            }
        },
    },
};
</script>
<style lang="css">
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #ec3237;
}
.tag {
    background: #0086fb;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-size: 14px;
}
.btn {
    height: 40px;
    border-radius: 0 !important;
}
.btn.primary {
    background: #ec3237 !important;
    height: 40px;
    color: white;
    font-weight: bold;
}
.primary-color {
    color: #ec3237 !important;
}
.content {
    padding: 10px !important;
}
.date {
    font-size: 12px;
    color: #5a5a5a;
}

.title {
    height: 100%;
    padding-left: 10px;
}
</style>
