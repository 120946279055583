<template>
    <div class="media-content card">
        <div class="row justify-content-between">
            <div class="col-md-2">
                <img class="img" :src="media.url + '/public'" :alt="caption" />
            </div>
            <div class="col-md">
                <div v-if="!editMode">
                    <h6 class="p-2 media-caption">{{ media.caption }}</h6>
                    <div class="p-2">{{ media.description }}</div>
                </div>
                <div class="p-2" v-else>
                    <div class="mb-2">
                        <input
                            class="form-control media-caption"
                            type="text"
                            placeholder="Image Caption"
                            v-model="caption"
                        />
                    </div>
                    <div>
                        <textarea
                            placeholder="Image description"
                            class="form-control desc-edit"
                            v-model="description"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div class="pt-3 col-md-3 buttons">
                <div class="d-flex justify-content-between">
                    <div></div>
                    <button @click="addToHome" :disabled="deleting" class="btn">
                        <b-spinner v-show="addHome" small></b-spinner>
                        <span v-if="!media.addedToHome">Add to Home</span>
                        <span v-if="media.addedToHome">Added to Home</span>
                    </button>
                    <button
                        @click="deleteMedia"
                        :disabled="deleting"
                        class="btn"
                    >
                        <b-spinner v-show="deleting" small></b-spinner>
                        Delete
                    </button>
                    <button
                        :disabled="saving"
                        @click="submit"
                        class="btn primary"
                    >
                        <b-spinner v-show="saving" small></b-spinner>
                        {{ editMode ? "Save" : "Edit" }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import apiService from "../services/apiService";
export default {
    props: {
        media: Object,
    },
    data() {
        return {
            caption: null,
            description: null,
            editMode: false,
            saving: false,
            deleting: false,
            addHome: false,
        };
    },
    methods: {
        async deleteMedia() {
            this.deleting = true;
            await apiService.deleteMedia(this.media.id);
            this.$emit("remove", this.media);
            this.deleting = false;
        },
        async addToHome() {
            if (this.media.addedToHome) {
                return;
            }
            this.addHome = true;
            await apiService.addToHome(this.media.id);
            this.addHome = false;
            // eslint-disable-next-line vue/no-mutating-props
            this.media.addedToHome = true;
        },
        async submit() {
            if (!this.editMode) {
                this.editMode = true;
                return;
            }

            this.saving = true;
            var response = await apiService.updateMedia(this.media.id, {
                caption: this.caption,
                description: this.description,
            });

            this.saving = false;
            this.editMode = false;

            this.$emit("update", response.data);
        },
    },
};
</script>
<style lang="css">
.desc-edit {
    width: 100% !important;
}
.img {
    width: 100%;
}
.media-content {
    cursor: pointer;
    margin-bottom: 10px;
}

.new-media {
    z-index: 99;
    background: rgb(255, 183, 100) !important;
}
.media-content:hover {
    z-index: 99;
    background: rgb(241, 241, 241);
}
.buttons {
    align-content: flex-end;
}
</style>
