import http from "axios";
import store from "@/store";
http.interceptors.request.use(
    function (config) {
        config.baseURL = "https://cms.youthinitiate.org/api";
        //config.baseURL = "http://localhost:56927/api";
        const token = store.getters.getUser?.token;
        if (token) {
            config.headers.Authorization = "Bearer " + token;
        }
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

class ApiService {
    login(user) {
        return http.post("/auth/login", user);
    }
    logout(user) {
        return http.post("/auth/logout", user);
    }
    forgot(email) {
        return http.post("/auth/forgot", {
            emailAddress: email,
        });
    }
    verify(email, code) {
        return http.get("/auth/confirmation?id=" + email + "/&code=" + code);
    }
    reset(payload) {
        return http.post("/auth/reset", payload);
    }
    changePassword(payload) {
        return http.post("/auth/change", payload);
    }
    refresh(user) {
        return http.post("/auth/refresh", user);
    }
    getAbout() {
        return http.get("/about/us");
    }
    getHome() {
        return http.get("/home/fetch");
    }
    getMedias() {
        return http.get("/media");
    }
    updateAbout(content) {
        let config = {
            header: {
                "Content-Type": "multipart/form-data",
            },
        };
        return http.post("/about/update", content, config);
    }
    updateMedia(id, payload) {
        return http.put("/media/edit/" + id, payload);
    }
    saveNews(content) {
        let config = {
            header: {
                "Content-Type": "multipart/form-data",
            },
        };
        return http.post("/news/create", content, config);
    }
    updateNews(id, data) {
        let config = {
            header: {
                "Content-Type": "multipart/form-data",
            },
        };
        return http.put("/news/update/" + id, data, config);
    }
    uploadBanner(content) {
        let config = {
            header: {
                "Content-Type": "multipart/form-data",
            },
        };
        return http.post("/media/update", content, config);
    }
    uploadMedia(content) {
        let config = {
            header: {
                "Content-Type": "multipart/form-data",
            },
        };
        return http.post("/media/upload", content, config);
    }
    deleteMedia(id) {
        return http.delete("/media/delete/" + id);
    }
    getMessages() {
        return http.get("/contact/messages");
    }
    getArticles(type) {
        return http.get("/news/status/" + type);
    }
    getArticle(id) {
        return http.get("/news/find/" + id);
    }
    updateArticleStatus(id, status) {
        return http.put("/news/update/" + id + "/status/" + status);
    }
    addToHome(id) {
        return http.put("media/" + id + "/add-to-home");
    }
    removeFromHome(id) {
        return http.delete("media/" + id + "/remove-from-home");
    }
}

export default new ApiService();
