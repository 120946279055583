<template>
    <div v-if="$route.name === 'News'" class="news">
        <div class="header d-flex justify-content-between">
            <h3 class="title">{{ $route.name }} - {{ selctedTab }}</h3>
            <button @click="$router.push('/news/new')" class="btn primary">
                New
            </button>
        </div>
        <div class="nav nav-pills">
            <button
                v-for="(tab, index) in tabs"
                :key="index"
                class="nav-link primary-color"
                :class="tab === selctedTab ? 'active  ' : 'in-active'"
                @click="selectTab(tab)"
            >
                {{ tab }}
            </button>
        </div>
        <div v-if="articles" class="articles">
            <Article
                @update="status"
                v-for="article in getArticles"
                :article="article"
                :key="article.id"
            />
        </div>
        <div v-show="loading" class="container text-center">
            <b-spinner class="m-5" label="Spinning"></b-spinner>
        </div>
    </div>
    <div v-else>
        <router-view></router-view>
    </div>
</template>
<script>
import Article from "../components/Article.vue";
import apiService from "../services/apiService";
export default {
    components: { Article },
    data() {
        return {
            selctedTab: "Published",
            tabs: ["Draft", "Published", "Archive"],
            articles: null,
            loading: false,
        };
    },
    methods: {
        async selectTab(name) {
            this.selctedTab = name;
            this.loading = true;
            var result = await apiService.getArticles(this.selctedTab);
            this.articles = result.data;
            this.loading = false;
        },
        status(data) {
            this.articles.find((article) => article.id === data.id).status =
                data.status;
        },
    },
    computed: {
        getArticles() {
            return this.articles.filter((article) => {
                return article.status === this.selctedTab;
            });
        },
    },
    mounted: async function () {
        this.loading = true;
        var result = await apiService.getArticles(this.selctedTab);
        this.articles = result.data;
        this.loading = false;
    },
};
</script>
<style lang="css" scoped>
.articles {
    padding-top: 10px;
}
.active {
    color: #ec3237;
    background: transparent !important;
    border-bottom: 2px solid #ec3237 !important;
}
.nav-link {
    border-radius: none !important;
}
.in-active {
    color: black !important;
    border-bottom: 2px solid transparent;
}
</style>
