<template>
    <div class="contact">
        <h3 class="title">{{ $route.name }}</h3>

        <table class="table table-borderless table-striped table-light">
            <thead>
                <tr>
                    <td scope="col">Name</td>
                    <td scope="col">Mobile</td>
                    <td scope="col">Email</td>
                    <td scope="col">DateTime</td>
                    <td scope="col">Message</td>
                </tr>
            </thead>
            <tbody>
                <ContactComponent
                    v-for="message in messages"
                    :key="message.id"
                    :message="message"
                />
            </tbody>
        </table>
        <div v-show="loading" class="container text-center">
            <b-spinner class="m-5" label="Spinning"></b-spinner>
        </div>
    </div>
</template>
<script>
import ContactComponent from "../components/ContactComponent.vue";
import apiService from "../services/apiService";
export default {
    components: {
        ContactComponent,
    },
    data() {
        return {
            messages: null,
            loading: false,
        };
    },

    mounted: async function () {
        this.loading = true;
        var result = await apiService.getMessages();
        this.messages = result.data;
        this.loading = false;
    },
};
</script>
<style lang="css" scoped>
.table {
    border-collapse: collapse;
}
</style>
