<template>
    <div class="sidebar d-flex justify-content-between">
        <div class="logo"></div>
        <ul class="links">
            <li class="nav-link" v-for="(nav, index) in navItems" :key="index">
                <router-link class="btn" :to="nav.path" exact>
                    {{ nav.name }}</router-link
                >
            </li>
            <li class="nav-link">
                <button @click="$store.commit('LOGOUT')" class="btn">
                    <span> Logout </span>
                </button>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    data() {
        return {
            navItems: [
                {
                    name: "Home",
                    path: "/home",
                    icon: "home",
                },
                {
                    name: "News",
                    path: "/news",
                    icon: "home",
                },
                {
                    name: "Media",
                    path: "/media",
                    icon: "home",
                },
                {
                    name: "Contact",
                    path: "/contact",
                    icon: "home",
                },
                {
                    name: "About",
                    path: "/about",
                },
            ],
        };
    },
};
</script>
<style lang="css" scoped>
.logo {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    position: absolute;
    top: 0;
    height: 50px;
    background: url("/logo.png") no-repeat;
    margin-left: 20px;
    margin-top: 20px;
}
.links {
    margin-top: 130px !important;
}
.sidebar {
    min-height: 100vh;
    position: sticky !important;
    height: 100vh;
    top: 0;
    border-right: 1px solid #ffddde;
    /* box-shadow: 2px 0 20px -2px #999999; */
    width: 112px !important;
    background: #ffffff;
}
li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    padding: 0;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.nav-link {
    border: none;
    width: 100%;
    margin-bottom: 20px;
    background: #ffffff;
    border-left: 5px solid white;
    color: black;
    text-align: start;
}

.nav-link:hover,
.nav-link.router-link-active,
.nav-link.router-link-exact-active {
    color: #ec3237;
    border-left: 5px solid #ec3237;
    background: #fbe1e3;
    font-weight: bold;
}
</style>
