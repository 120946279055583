<template>
    <transition :key="article.id" name="slide-fade">
        <div @click="edit" class="article-min card">
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <div class="image">
                        <img
                            :src="article.banner + '/twide'"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <div class="p-2">
                        <h3>{{ article.title }}</h3>
                        <p class="date">
                            {{ date(article.date) }}
                        </p>
                        <div class="tags d-flex">
                            <span
                                class="tag"
                                v-for="(tag, index) in getTags"
                                :key="index"
                            >
                                {{ tag }}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="action-buttons">
                        <button
                            @click.stop="archive"
                            class="btn"
                            :disabled="loading.archiving"
                            v-if="article.status === 'Published'"
                        >
                            <b-spinner
                                v-show="loading.archiving"
                                small
                            ></b-spinner>

                            Archive
                        </button>
                        <button
                            @click.stop="unpublish"
                            class="btn"
                            :disabled="loading.unPublishing"
                            v-if="article.status === 'Published'"
                        >
                            <b-spinner
                                v-show="loading.unPublishing"
                                small
                            ></b-spinner>

                            UnPublish
                        </button>

                        <button
                            @click.stop="edit"
                            class="btn"
                            v-if="article.status === 'Draft'"
                        >
                            Edit
                        </button>
                        <button
                            :disabled="loading.publishing"
                            @click.stop="publish"
                            class="btn primary"
                        >
                            <b-spinner
                                v-show="loading.publishing"
                                small
                            ></b-spinner>

                            Publish
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import moment from "moment";
import apiService from "../services/apiService";

export default {
    props: {
        article: Object,
    },
    data() {
        return {
            loading: {
                publishing: false,
                unPublishing: false,
                archiving: false,
            },
        };
    },
    methods: {
        date() {
            return moment().format("MMMM DD, yyyy");
        },
        edit() {
            this.$router.push({
                path: "/news/edit/",
                query: { id: this.article.id },
            });
        },
        async unpublish() {
            this.unpublishing = true;
            var response = await apiService.updateArticleStatus(
                this.article.id,
                "Draft"
            );

            this.$emit("update", response.data);
            this.unpublishing = false;
        },
        async publish() {
            this.loading.publishing = true;
            var response = await apiService.updateArticleStatus(
                this.article.id,
                "Published"
            );
            this.$emit("update", response.data);
            this.loading.publishing = false;
        },
        async archive() {
            this.loading.archiving = true;
            var response = await apiService.updateArticleStatus(
                this.article.id,
                "Archive"
            );
            this.$emit("update", response.data);
            this.loading.archiving = true;
        },
    },
    computed: {
        getTags() {
            return this.article.tags.split(",");
        },
    },
};
</script>
<style lang="css" scoped>
.article-min {
    padding: 10px;
    cursor: pointer;
    margin-bottom: 10px;
}

.article-min:hover {
    background: rgb(241, 241, 241);
}
</style>
