import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import BootstrapVue3 from "bootstrap-vue-3";
import { quillEditor, Quill } from "vue3-quill";
import ImageUploader from "quill-image-uploader";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
library.add(faUserSecret);

Quill.register("modules/imageUploader", ImageUploader);
const app = createApp(App);
app.component("icon", FontAwesomeIcon);
app.use(BootstrapVue3);
app.config.globalProperties.$msalInstance = {};
app.use(quillEditor);
app.use(PerfectScrollbar);

app.use(store).use(router).mount("#app");
