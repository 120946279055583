<template>
    <div class="login">
        <div class="centered">
            <div>
                <div class="logo"></div>
                <h4
                    style="margin-left: 100px; margin-top: 20px; color: #858688"
                >
                    Youth <br />
                    Initiate
                </h4>
            </div>
            <form @submit.prevent class="my-5 p-2">
                <input
                    type="email"
                    placeholder="Email address"
                    class="form-control"
                    v-model="email"
                />
                <input
                    type="password"
                    placeholder="Password"
                    class="my-3 form-control"
                    v-model="password"
                />
                <p class="text-danger">{{ error }}</p>
                <button
                    @click="login"
                    class="btn form-control primary text-white fw-bold mt-4"
                >
                    <b-spinner v-show="loading" small></b-spinner>

                    Login
                </button>

                <button @click="reset" class="btn text-center">
                    <b-spinner v-show="loading_reset" small></b-spinner>
                    Reset password
                </button>
                <p class="mt-5 copyright">Copyright © 2021</p>
            </form>
        </div>
    </div>
</template>
<script>
import apiService from "../../services/apiService";
export default {
    data() {
        return {
            email: null,
            password: null,
            loading: false,
            error: "",
            loading_reset: false,
        };
    },
    methods: {
        async login() {
            this.loading = true;
            apiService
                .login({
                    email: this.email,
                    password: this.password,
                })
                .then(
                    (response) => {
                        this.loading = false;
                        this.$store.commit("LOGIN_SUCCESS", response.data);
                        this.$router.push("/");
                    },
                    (error) => {
                        this.loading = false;
                        this.error = error.response.data;
                    }
                );
        },
        async reset() {
            if (this.email === null) {
                alert("Please enter email first");
                return;
            }
            this.loading_reset = true;
            apiService
                .reset({
                    email: this.email,
                    password: this.password,
                })
                .then(
                    (response) => {
                        this.loading_reset = false;
                        alert(response.data);
                    },
                    (error) => {
                        this.loading = false;
                        this.error = error.response.data;
                    }
                );
        },
    },
};
</script>
<style lang="css" scoped>
.copyright {
    font-size: 12px;
    color: gray;
    text-align: center;
}
.primary {
    background: #ec3237;
}
.logo {
    min-width: 100px;
    min-height: 100px;
    width: 100px;
    position: absolute;
    top: 0;
    height: 50px;
    background: url("/logo.png") no-repeat;
    margin-left: 20px;
    margin-top: 20px;
}
.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    background: rgb(255, 255, 255);
    border: 1px solid rgb(187, 187, 187);
    padding: 1rem;
}
</style>
