<template>
    <div class="banner d-flex">
        <div>
            <img :src="banner.path + '/public'" />
        </div>
        <div class="p-2">
            <h6>{{ banner.title }}</h6>
            <p>{{ banner.caption }}</p>
            <p>{{ banner.description }}</p>
        </div>
        <div style="margin-left: auto">
            <button
                :disabled="loading"
                @click.stop="removeFromHome"
                class="mx-auto btn primary"
            >
                <b-spinner v-show="loading" small></b-spinner>
                Remove from Home
            </button>
        </div>
    </div>
</template>
<script>
import apiService from "../services/apiService";
export default {
    props: {
        banner: Object,
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        select() {
            this.$emit("toggle");
        },
        async removeFromHome() {
            this.loading = true;
            await apiService.removeFromHome(this.banner.id);
            this.loading = false;
            this.$emit("remove", this.banner);
        },
    },
};
</script>
<style lang="css" scoped>
.banner {
    background: #ececec;
    padding: 10px;
    cursor: pointer;
    margin: 10px;
    transition: all 0.3s ease-out;
}
.banner img {
    height: 150px;
    background-size: contain !important;
}
</style>
