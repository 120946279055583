import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
import Main from "../layouts/Main.vue";
import Auth from "../layouts/Auth.vue";
import Home from "../views/Home";
import About from "../views/About";
import News from "../views/News";
import New from "../views/News/New";
import Edit from "../views/News/Edit";
import Media from "../views/Media";
import Users from "../views/Users";
import Contact from "../views/Contact";
import Login from "../views/Auth/Login.vue";

const routes = [
    {
        path: "/",
        name: "Main",
        redirect: "/home",
        component: Main,
        meta: { authorize: true },
        children: [
            {
                path: "home",
                meta: { authorize: true },
                name: "Home",
                component: Home,
            },
            {
                path: "news",
                name: "News",
                meta: { authorize: true },
                component: News,
                children: [
                    {
                        path: "new",
                        name: "New Article",
                        component: New,
                    },
                    {
                        path: "edit",
                        name: "Edit Article",
                        component: Edit,
                    },
                ],
            },
            {
                path: "about",
                meta: { authorize: true },
                name: "About",
                component: About,
            },
            {
                path: "contact",
                meta: { authorize: true },
                name: "Contact",
                component: Contact,
            },
            {
                path: "media",
                name: "Media",
                meta: { authorize: true },
                component: Media,
            },
            {
                path: "users",
                meta: { authorize: true },
                name: "Users",
                component: Users,
            },
        ],
    },
    {
        path: "/auth",
        name: "Auth",
        redirect: "/login",
        meta: { authorize: false },
        component: Auth,
        children: [
            {
                path: "login",
                name: "Login",
                meta: { authorize: false },
                component: Login,
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const { authorize } = to.meta;
    const currentUser = store.getters.getUser;
    if (authorize) {
        if (!currentUser) {
            return next({ path: "/auth/login" });
        }
    }
    next();
});

export default router;
