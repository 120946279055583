<template>
    <div class="media">
        <div class="header d-flex justify-content-between">
            <h3 class="title">{{ $route.name }}</h3>
            <div class="d-flex">
                <input
                    class="search mx-2"
                    placeholder="Search media"
                    type="text"
                    name="Search"
                    v-model="searchPhrase"
                />
            </div>
        </div>
        <div v-if="medias" class="gallery">
            <div class="mb-2 d-flex justify-content-between">
                <h6>Drag and drop media files</h6>
                <input
                    @change="fileSelected"
                    type="file"
                    ref="fileUpload"
                    hidden
                />
                <button
                    :disabled="uploading"
                    @click="upload"
                    class="btn primary"
                >
                    <b-spinner v-show="uploading" small></b-spinner>
                    Upload
                </button>
            </div>
            <media-component
                @update="update"
                @remove="remove"
                v-for="media in search"
                :key="media.id"
                :media="media"
            />
        </div>
        <div v-show="page_loading" class="container text-center">
            <b-spinner class="m-5" label="Spinning"></b-spinner>
        </div>
    </div>
</template>
<script>
import MediaComponent from "../components/MediaComponent";
import apiService from "../services/apiService";
export default {
    components: {
        MediaComponent,
    },
    data() {
        return {
            medias: null,
            page_loading: true,
            uploading: false,
            deleting: false,
            searchPhrase: "",
        };
    },
    methods: {
        update(data) {
            var media = this.medias.find((media) => media.id === data.id);
            media.caption = data.caption;
            media.description = data.description;
        },
        remove(data) {
            this.medias.splice(this.medias.indexOf(data), 1);
        },
        upload() {
            this.$refs.fileUpload.click();
        },
        async fileSelected(e) {
            const files = e.target.files;
            if (files.length > 1) {
                alert("Only one image should be selected");
                return;
            }
            const file = e.target.files[0];
            let form = new FormData();
            form.append("file", file);
            this.uploading = true;
            var response = await apiService.uploadMedia(form);
            this.medias.push(response.data);
            this.uploading = false;
        },
    },
    computed: {
        search() {
            return this.medias.filter((media) => {
                return media.caption
                    .toLowerCase()
                    .match(this.searchPhrase.toLowerCase());
            });
        },
    },
    async mounted() {
        var result = await apiService.getMedias();
        this.medias = result.data;
        this.page_loading = false;
    },
};
</script>
<style lang="css" scoped>
.media {
    overflow: hidden !important;
    padding-left: 20px;
    padding-right: 20px;
}
.search {
    height: 40px;
    padding: 10px;
    width: 350px;
    border: 1px solid rgb(184, 184, 184);
}
.gallery {
}
</style>
