<template>
    <div class="users">
        <div class="header d-flex justify-content-between">
            <h3 class="title">{{ $route.name }} - {{ selctedTab }}</h3>
            <button @click="$router.push('/news/new')" class="btn primary">
                New
            </button>
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style lang=""></style>
